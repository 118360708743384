import { ContributerData, mergeContributorDataByRepo } from "./grouping";

export const getGithubTreemapData = (githubData: ContributerData) => {
    const githubUserData = mergeContributorDataByRepo(githubData);

    const repos = Object.keys(githubUserData).map(repo => {

        const children = Object.keys(githubUserData[repo]).map((contributor: any) => {
            const contributerData = githubUserData[repo][contributor] as Record<string, number>;
            const commitCount = contributerData.commits || 0;
            const prsCount = contributerData.prs || 0;

            return {
                title: contributor,
                color: 'red',
                size: 50,
                opacity: 0.4,
                children: [
                    { 
                        title: `Commits: ${commitCount}`, 
                        color: 'blue', 
                        size: commitCount + 1,
                    },
                    { 
                        title: `PRs: ${prsCount}`, 
                        color: 'green', 
                        size: prsCount + 1,
                    },
                ],
            };
        });

        return {
            title: repo,
            color: 'black',
            size: 200,
            opacity: 0.4,
            children
        }
    });

    return {
        title: 'root',
        color: 'transparent',
        children: repos,
    };
};

export const formatGroupedDateData = (groupedDateData: Record<string, any>) => {
    return Object.keys(groupedDateData).map(date => {
        return {
            ...groupedDateData[date],
            name: date
        }
    }).sort((a, b) => new Date(a.name).getTime() - new Date(b.name).getTime());
}