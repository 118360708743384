import { useEffect, useMemo } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLocation, useNavigate } from 'react-router';

import { redirectToJiraOAuthFlow, getAccessToken } from '../hooks';
import { ROUTES } from '../routes';
import { useJiraOAuthParamsValue, useSetAuthToken, useSetJiraAccessToken, useSetJiraOAuthParamsStorage } from '../state';

export const JiraOAuthPage = () => {
    const auth = useAuth();
    const setAuthToken = useSetAuthToken();
    const { search } = useLocation();
    const navigate = useNavigate();
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);

    const setAccessToken = useSetJiraAccessToken();
    const jiraOAuthParams = useJiraOAuthParamsValue();
    const setJiraOAuthParamsStorage = useSetJiraOAuthParamsStorage();

    useEffect(() => {
        if(auth.isAuthenticated){
            setAuthToken(auth.user?.access_token);
        }
    }, [auth.isAuthenticated, auth.user, setAuthToken]);

    useEffect(() => {
        const code = queryParams.get('code');

        if(jiraOAuthParams && jiraOAuthParams.client_id){
            setJiraOAuthParamsStorage(jiraOAuthParams);

            if(!code) {
                redirectToJiraOAuthFlow(jiraOAuthParams.client_id);
            }else{
                try{
                    getAccessToken(code as string, jiraOAuthParams.client_id, jiraOAuthParams.secret).then((accessToken) => {
                        setAccessToken(accessToken);
                        window.history.replaceState({},
                            window.document.title,
                            window.location.origin + window.location.pathname);
                        navigate(ROUTES.DASHBOARD);
                    });
                }catch(e){
                    console.log('There was an OAuth error', e);
                }
            }
        }

    }, [jiraOAuthParams, navigate, queryParams, setAccessToken, setJiraOAuthParamsStorage]);

    return (
        <div>You are being redirected. Hang Tight</div>
    );
};

export default JiraOAuthPage;
