import { CartesianGrid, CurveProps, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { getRandomColor } from '../../utils';

export type LineData = {
    dataKey: string;
    stroke?: string;
    type?: CurveProps['type'];
};

export type LineChartConfig = {
    dataKeyName?: string;
    syncId?: string;
    width?: number;
    height?: number;
    includeTooltip?: boolean;
    includeLegend?: boolean;
    customLegendFormatter?: (value: any, entry: any) => JSX.Element;
    customTooltipFormatter?: (value: any, name: any, props: any) => string[];
    dataKeyFormatCallback?: (value: any) => string;
};

export type LineChartParams = {
    data: Record<string, unknown>[];
    graphAxisData: LineData[];
    config?: LineChartConfig;
};

const CustomXAxisTick: React.FC<{ dataKeyFormatCallback: LineChartConfig['dataKeyFormatCallback'] }> = ({ x, y, stroke, payload, dataKeyFormatCallback }: any) => (
<g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
        {dataKeyFormatCallback ? dataKeyFormatCallback(payload.value) : payload.value}
    </text>
</g>
);

export const lineChart = ({ data, graphAxisData, config }: LineChartParams) => (
    <LineChart 
        width={config?.width || 800} 
        height={config?.height || 500} 
        data={data}
        margin={{ top: 50, right: 20, left: 20, bottom: 150 }}
        syncId={config?.syncId || undefined}
    >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey={config?.dataKeyName || 'name'} tick={<CustomXAxisTick dataKeyFormatCallback={config?.dataKeyFormatCallback} />} textAnchor='end' interval={0} dy={10} angle={-40} stroke='#8884d8' />
        <YAxis />
        {config?.includeTooltip && <Tooltip formatter={config?.customTooltipFormatter ? config?.customTooltipFormatter : undefined} />}
        {config?.includeLegend && <Legend verticalAlign="top" formatter={config?.customLegendFormatter ? config?.customLegendFormatter : undefined} />}
        {graphAxisData.map(({ dataKey, stroke, type }) => (
            <Line type={type || 'monotone'} dataKey={dataKey} stroke={stroke || getRandomColor()} connectNulls />
        ))}
    </LineChart>
);
