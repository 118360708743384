import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useAuth } from 'react-oidc-context';

const authTokenAtom = atom('');

export const githubTokenAtom = atom(async (get) => {
    if(process.env.IS_LOCAL === 'true'){
        return process.env.GITHUB_TOKEN;
    }else{
        const res = await fetch(`https://${process.env.API_PUBLIC_URI}/token`, {
            headers: {
                'Authorization': `Bearer ${get(authTokenAtom)}`,
                'Content-Type': 'application/json',
                'x-api-key': process.env.API_GATEWAY_API_KEY!
            }
        });
    
       return (await res.json()).value;
    }
});

export const useGithubTokenValue = () => {
    const auth = useAuth();
    useSetAtom(authTokenAtom)(auth.user?.access_token as string);
    return useAtomValue(githubTokenAtom);
};
