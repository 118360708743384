import { CodeAnalysisResult } from "./codeAnalysis";

export enum GithubPRStats {
    MIN_TIME_TO_MERGE = 'Minimum Time to Merge',
    MAX_TIME_TO_MERGE = 'Maximum Time to Merge',
    AVERAGE_TIME_TO_MERGE = 'Average Time to Merge (Days)',
    AVERAGE_COMMENT_COUNT = 'Average Comment Count',
    MIN_CHANGED_FILES = 'Minimum Changed Files',
    MAX_CHANGED_FILES = 'Maximum Changed Files',
    AVERAGE_CHANGED_FILES = 'Average Changed Files',
};
export type GithubPRStatsKeys = keyof typeof GithubPRStats;

export enum GithubVulnerabilityStats {
    AVERAGE_TIME_TO_RESOLVE = 'Average Time to Resolve',
    TOTAL_OPEN_VULNERABILITIES = 'Total Open Vulnerabilities',
    TOTAL_FIXED_VULNERABILITIES = 'Total Fixed Vulnerabilities',
};
export type GithubVulnerabilityStatsKeys = keyof typeof GithubVulnerabilityStats;

export enum GithubWorkflowStats {
    AVERAGE_DEPLOYMENT_DURATION = 'Average Deployment Duration',
    TOTAL_SUCCESSFUL_DEPLOYMENTS = 'Total Successful Deployments',
    TOTAL_FAILED_DEPLOYMENTS = 'Total Failed Deployments',
    DEPLOYMENT_SUCCESS_PERCENTAGE = 'Deployment Success Percentage',
};
export type GithubWorkflowStatsKeys = keyof typeof GithubWorkflowStats;

export const githubOverallStats = {...GithubPRStats, ...GithubVulnerabilityStats, ...GithubWorkflowStats};

export type GithubContributerResults = {
    repo: Record<string, {
        totals: Record<string, number>,
        byDate: Record<string, Record<string, number>>,
    }>,
    calendar: { weeks: Record<string, any>[] },
};
export type GraphQLContributerResults = Record<string, Record<string, string | number>>;

export type GithubWorkflow = {
    name: string;
    runs: Record<string, any>[];
};

export type GithubRepoResult = {
    name: string,
    url: string,
    description: string,
    primaryLanguage: { name: string },
    languages: { nodes: { name: string }[] },
    issues: { totalCount: number },
    createdAt: string,
    pushedAt: string,
    updatedAt: string,
    pullRequests: Record<string, any>[],
    vulnerabilities: Record<string, any>[],
    workflows?: GithubWorkflow[],
    codeAnalysis?: CodeAnalysisResult[],
};