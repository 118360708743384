import { CodeAnalysisResult, GithubRepoResult, CalendarDateUnit, CodeAnalysisStatsKeys } from "../../types";
import { getDateFromGrouping } from "../../utils";
import {  mapRepoDataByDate } from "../github";

export const getCodeAnalysisLanguages = (results: CodeAnalysisResult[]) => results.map(({ Name }) => Name);

export const getCodeAnalysisByLanguage = (results: CodeAnalysisResult[], language: string) => results.find(({ Name }) => Name === language); 

export const mapRepoCodeAnalysisByDate = (results: GithubRepoResult[], dateGrouping = CalendarDateUnit.MONTH) => {
    return mapRepoDataByDate<CodeAnalysisResult>(
        results, 
        'codeAnalysis', 
        (_record, dateGrouping) => getDateFromGrouping((_record as CodeAnalysisResult).createdAt, dateGrouping), 
        getAveragesFromCodeAnalysis, 
        dateGrouping
    );
};

export const getAveragesFromCodeAnalysis = (codeAnalysis: CodeAnalysisResult[]): Record<CodeAnalysisStatsKeys, number | undefined> => {
    return {
        LINES_OF_CODE: codeAnalysis.reduce((total, { Lines }) => total + Lines, 0),
        LINES_OF_COMMENTS: codeAnalysis.reduce((total, { Comment }) => total + Comment, 0),
        COMPLEXITY_RATING: codeAnalysis.reduce((total, { Complexity }) => total + Complexity, 0),
        COUNT_OF_FILES: codeAnalysis.reduce((total, { Lines }) => total + Lines, 0),
    };
};