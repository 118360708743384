import axios from 'axios';
import { getDiffFromCurrentDate, getFormattedDate } from '../../utils';

const getAPIBaseURL = (siteId: string) => `https://api.atlassian.com/ex/jira/${siteId}/rest/api/3`;

export const redirectToJiraOAuthFlow = (client_id: string, state = '12345') => {
    const url = process.env.JIRA_OAUTH_URL!.replace('{client_id}', client_id).replace('{redirect_uri}', encodeURIComponent(process.env.JIRA_OAUTH_CALLBACK!)).replace('{state}', state);
    return window.location.replace(url);
};

export const getAccessToken = async (code: string, client_id: string, client_secret: string) => {
    const results = await axios.post('https://auth.atlassian.com/oauth/token', {
        grant_type: "authorization_code",
        client_id: client_id,
        client_secret: client_secret,
        code: code,
        redirect_uri: process.env.JIRA_OAUTH_CALLBACK
    }, {
        headers: {
            "Content-Type": "application/json",
        }
    });
    return results.data.access_token;
};

export const getSiteId = async (accessToken: string) => {
    try{
        const results = await axios.get("https://api.atlassian.com/oauth/token/accessible-resources",  {
            headers: { ...getCommonHeaders(accessToken) }
        });
        return results.data[0].id;
    }catch(e){
        console.log(e);
    }

    return undefined;
};

export const getJiraIssues = async (jql: string, accessToken: string, siteId: string, startDate = getDiffFromCurrentDate(-90), endDate = new Date(), startAt = 0): Promise<Record<string, any>[]> => {
    const results = await axios.post(`${getAPIBaseURL(siteId)}/search`, {
        jql: `(${jql}) AND createdDate >= "${getFormattedDate(startDate)}" AND createdDate <= "${getFormattedDate(endDate)}"`,
        startAt,
        maxResults: 1000,
        fields: [
            "summary",
            "status",
            "assignee",
            "issuetype",
            "createdDate"
        ]
    }, {
        headers: { ...getCommonHeaders(accessToken) }
    });

    if(results.data.total > (results.data.startAt + results.data.maxResults)){
        const nextResults = await getJiraIssues(jql, accessToken, siteId, startDate, endDate, results.data.startAt + results.data.maxResults);
        return [...results.data.issues, ...nextResults];
    }

    return results.data.issues;
};

export const getIssueChangelog = async (accessToken: string, siteId: string, issueId: string) => {
    const results = await axios.get(`${getAPIBaseURL(siteId)}/issue/${issueId}/changelog`, {
        headers: { ...getCommonHeaders(accessToken) }
    });
    return results.data;
};

const getCommonHeaders = (accessToken: string) => {
    return  {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json"
    };
};
