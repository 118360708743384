import { Legend, Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';
import { getRandomColor } from '../../utils';

export type RadarData = {
    dataKey: string;
    stroke?: string;
    fill?: string;
    opacity?: number;
};

export type RadarChartConfig = {
    dataKeyName?: string;
    width?: number;
    height?: number;
    includeGrid?: boolean;
    includeRaduisAxis?: boolean;
};

export type RadarChartParams = {
    data: Record<string, unknown>[];
    graphAxisData: RadarData[];
    config?: RadarChartConfig;
};

const dataKeyColors: Record<string, string> = {};
const getDataKeyColor = (dataKey: string) => {
    if(!dataKeyColors[dataKey]){
        dataKeyColors[dataKey] = getRandomColor();
    }

    return dataKeyColors[dataKey];
};

export const radarChart = ({ data, graphAxisData, config}: RadarChartParams) => (
    <ResponsiveContainer width={config?.width || 800} height={config?.height || 1000}>
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
          {config?.includeGrid && <PolarGrid />}
          <PolarAngleAxis dataKey={config?.dataKeyName || 'name'} />
          {config?.includeRaduisAxis && <PolarRadiusAxis />}
          {graphAxisData.map(({ dataKey, stroke, fill, opacity }) => (
            <Radar dataKey={dataKey} stroke={stroke || getDataKeyColor(dataKey)} fill={fill || getDataKeyColor(dataKey)} fillOpacity={opacity || 0.6} />
          ))}
          <Legend />
        </RadarChart>
      </ResponsiveContainer>
);