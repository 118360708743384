import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage, loadable } from 'jotai/utils';

const authTokenAtom = atom<string | undefined>(undefined);
export const useSetAuthToken = () => useSetAtom(authTokenAtom);

export const jiraOAuthParamsAtom = atom(async (get) => {
    const authToken = get(authTokenAtom);
    const cachedParams = get(jiraOAuthParamsWithStorageAtom);

    if(cachedParams && typeof cachedParams.client_id !== 'undefined'){
        return cachedParams;
    }

    if(process.env.IS_LOCAL === 'true'){
        return {
            client_id: process.env.JIRA_CLIENT_ID,
            secret: process.env.JIRA_CLIENT_SECRET
        };
    }

    if(typeof authToken !== 'undefined'){
        const isLocal = process.env.IS_LOCAL! === 'true' ? 'isLocal=true' : '';

        const res = await fetch(`https://${process.env.API_PUBLIC_URI}/jira-params?${isLocal}`, {
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
                'x-api-key': process.env.API_GATEWAY_API_KEY!
            }
        });

        return await res.json();
    }
});

const jiraOAuthParamsWithStorageAtom = atomWithStorage<Record<string, string>>('jiraOauth', {});
export const useJiraOAuthParamsWithStorageValue = () => useAtomValue(jiraOAuthParamsWithStorageAtom);
export const useSetJiraOAuthParamsStorage = () => useSetAtom(jiraOAuthParamsWithStorageAtom);

export const jiraAccessTokenAtom = atomWithStorage('jiraAccessToken', '');

export const useJiraOAuthParamsValue = () => useAtomValue(jiraOAuthParamsAtom);
export const useJiraAccessTokenValue = () => useAtomValue(jiraAccessTokenAtom);
export const useSetJiraAccessToken = () => useSetAtom(jiraAccessTokenAtom);
export const useJiraOAuthHasLoaded = () => {
    const [{ state }] = useAtom(loadable(jiraOAuthParamsAtom));

    return state === 'hasData' || state === 'hasError';
};