import React, { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthProviderProps, useAuth } from 'react-oidc-context';
import { User, UserManager } from 'oidc-client-ts';
import { ROUTES } from '../routes';

export const SSO_CALLBACK = process.env.SSO_CALLBACK!;
export const SSO_SCOPES = 'openid profile';
export const SSO_CLIENT_ID = process.env.SSO_CLIENT_ID!;
export const SSO_BASE = 'https://ndcsso.asurion.com:9031';
export const SSO_AUTHORIZATION_ENDPOINT = `${SSO_BASE}/as/authorization.oauth2`;
export const SSO_USERINFO_ENDPOINT = `${SSO_BASE}/idp/userinfo.openid`;
export const END_SESSION_ENDPOINT = `${SSO_BASE}/idp/startSLO.ping`;
export const TOKEN_ENDPOINT = `${SSO_BASE}/as/token.oauth2`;

const userManager = new UserManager({
    authority: SSO_AUTHORIZATION_ENDPOINT,
    client_id: SSO_CLIENT_ID,
    redirect_uri: SSO_CALLBACK,
    scope: SSO_SCOPES,
    metadata: {
        issuer: SSO_BASE,
        authorization_endpoint: SSO_AUTHORIZATION_ENDPOINT,
        userinfo_endpoint: SSO_USERINFO_ENDPOINT,
        end_session_endpoint: END_SESSION_ENDPOINT,
        token_endpoint: TOKEN_ENDPOINT,
    },
});

const authSigninCallback = (_user: User | void): void => {
    window.history.replaceState(
        {},
        document.title,
        window.location.pathname,
    );
};

export const oidcConfig: AuthProviderProps = {
    userManager,
    onSigninCallback: authSigninCallback,
};

export const withAuth = <P extends object>(
    Component: React.ComponentType<P>,
): FunctionComponent<P> => (props) => {
    const auth = useAuth();
    const navigate = useNavigate();

    // bypass auth for local envs
    if(process.env.IS_LOCAL === 'true'){
        return <Component {...props} />;
    }

    useEffect(() => {
        if (!auth.isAuthenticated && !auth.isLoading && !auth.error) {
            navigate(ROUTES.LOGIN);
        }
    }, [auth, navigate]);

    if (auth.error) {
        if(auth.error.message.includes('No matching state found in storage')){
            window.location.reload();
        }else{
            console.error(`Auth error: ${auth.error.message}`);
            return <div>Unable to authenticate. Are you on the VPN?</div>;
        }
    }

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    return auth.isAuthenticated
        ? <Component {...props} />
        : <div>You are unauthorized to view this content.</div>;
};