import { CalendarDateUnit, GithubContributerResults } from "../types";
import { getDateFromGrouping } from "./dateFormats";

export const groupDataByDate = (data: Record<string, any>[], getDateCallback = (arr: any) => arr.created_at, groupByDateRange = 'week') => {
    const propertyCallback = (arr: Record<string, any>) => {
        const date = new Date(getDateCallback(arr));
        let dateRange: string = '';

        switch(groupByDateRange){
            case 'day':
                dateRange = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
                break;

            case 'week':
                dateRange = `${date.getFullYear()}-${date.getMonth() + 1}-${Math.ceil(date.getDate()/7)}`;
                break;
            
            case 'month':
                dateRange = `${date.getFullYear()}-${date.getMonth() + 1}`;
                break;
        }
        return dateRange;
    };

    return groupDataByProperty(data, propertyCallback);
};

export const groupDataByProperty = (data: Record<string, any>[], property: string | ((arr: Record<string, any>) => string)) => {
    const groupedData: Record<string, any> = {};
    data.forEach(arr => {
        const propertyValue = typeof property === 'function' ? property(arr) : arr[property];
        if(!groupedData[propertyValue]){
            groupedData[propertyValue] = [];
        }

        groupedData[propertyValue].push(arr);
    });

    return groupedData;
};

export type ContributerData = Record<string, Record<string, unknown>>;

export const mergeContributorDataByRepo = (contributorData: ContributerData) => {
    const mergedByRepo: ContributerData = {};
    Object.keys(contributorData).forEach((username) => {
        Object.keys(contributorData[username]).forEach((repo) => {
            if(typeof mergedByRepo[repo] === 'undefined'){
                mergedByRepo[repo] = {};
            }

            mergedByRepo[repo][username] = contributorData[username][repo];
        });
    });

    return mergedByRepo;
};

export const mapContributionsCalendarToDateGrouping = (results: GithubContributerResults['calendar'], grouping = CalendarDateUnit.MONTH): Record<string, Record<string, number>> => {
    const mappedResults: Record<string, Record<string, number>> = {};
    results.weeks.forEach((week: Record<string, any>) => {
        week.contributionDays.forEach(({ date, contributionCount }: Record<string, any>) => {
            const dateGrouping = getDateFromGrouping(date, grouping);
            if(typeof dateGrouping !== 'undefined'){
                if(!mappedResults[dateGrouping]){
                    mappedResults[dateGrouping] = {
                        contributions: 0,
                    };
                }
                mappedResults[dateGrouping].contributions += contributionCount;
            }
        });
    });
    return mappedResults;
};

