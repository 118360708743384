import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {   
    QueryClient,
    QueryClientProvider
} from '@tanstack/react-query';
import styled from 'styled-components';
import { AsurionDoodleSpinner, Button, Datepicker, Dropdown, Switch, TextField } from '@soluto-private/mx-asurion-ui-react';

import { getContributorData, getJiraIssues, useGetReposByTag, getSiteId, mapRepoCodeAnalysisByDate, mapRepoPullRequestsByDate, mapRepoResults, mapRepoVulnerabilitiesByDate, useGetCodeAnalysisResults, setGithubToken, getIssuesEnrichedWithChangelog, enrichIssuesWithWipData, mapRepoWorkflowsByDate, filterRepoWorkflows } from '../hooks';
import { getDiffFromCurrentDate, getDisplayDateFromGrouping, mapContributionsCalendarToDateGrouping } from '../utils';
import { getGraph, GraphType } from '../factories';
import { CalendarDateUnit, CodeAnalysisStats, GithubContributerResults, githubOverallStats, GithubPRStats, GithubRepoResult, GithubVulnerabilityStats, GithubWorkflowStats, IssuesWithChangelogResponse, JiraStats } from '../types';
import { useGithubTokenValue, useJiraAccessTokenValue } from '../state';
import { ROUTES } from '../routes';

const RENDER_USER_FORM = false; // set to true to render user form

const Container = styled.div`
    max-width: 1600px;
`;

const LoaderDoodleContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const InputsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;

    margin-top: 20px;
    margin-bottom: 20px;

    & > * {
        width: 200px !important;
        min-width: 0 !important;
    }
`;

const GraphsContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 10px;
    row-gap: 15px;
`;

const GraphContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`;

const FIELDS_TO_COLLECT: Record<string, string> = {
    jiraUsername: 'Jira Username',
    githubUsername: 'Github Username',
};

type UserData = {
    jira: Record<string, Record<string, unknown>>;
    github: Record<string, GithubContributerResults>;
}

const DevStats = () => {
    const [siteId, setSiteId] = useState('');
    const [jiraProject, setJiraProject] = useState('FE');
    const [jiraData, setJiraData] = useState(undefined as unknown as IssuesWithChangelogResponse);
    const [users, _setUsers] = useState([] as Record<string, any>[]);
    const [userData, setUserData] = useState({} as UserData);
   
    const [repoData, setRepoData] = useState([] as GithubRepoResult[]);
    const [repoTag, setRepoTag] = useState('sonash-first-experience');

    const [deploymentWorkflow, setDeploymentWorkflow] = useState({} as Record<string, string>);

    const [startDate, setStartDate] = useState(getDiffFromCurrentDate(-90));
    const [endDate, setEndDate] = useState(new Date());
    const [graphType, setGraphType] = useState(GraphType.LINE);
    const [calendarGrouping, setCalendarGrouping] = useState(CalendarDateUnit.MONTH);
    const [graphedStatistics, setGraphedStatistics] = useState([] as string[]);

    const [isLoading, setIsLoading] = useState(false);

    const allStatistics = useMemo(() => ({...githubOverallStats, ...CodeAnalysisStats, ...JiraStats}), []);


    // hooks
    const navigate = useNavigate();
    const { getReposByTag } = useGetReposByTag();
    const { getCodeAnalysisResults, data: codeAnalysisFiles } = useGetCodeAnalysisResults();
    const githubToken = useGithubTokenValue();
    const jiraAccessToken = useJiraAccessTokenValue();

    // effects 
    useEffect(() => {
        setGithubToken(githubToken);
    }, [githubToken]);


    useEffect(() => {
        if(jiraAccessToken){
            getSiteId(jiraAccessToken).then((_siteId) => {
                if(typeof _siteId === 'undefined'){
                    setSiteId('');
                    console.log('SITE ID NOT SET, REDIRECTING FOR OAUTH');
                    return navigate(ROUTES.JIRA_OAUTH);
                }else{
                    setSiteId(_siteId);
                }
            });
        }
    }, [jiraAccessToken, navigate]);

    // callbacks
    const setUsers = useCallback((index: number, data: string, key: string) => {
        const _users = users;
        _users[index][key] = data;
        _setUsers([..._users]);
    }, [users]);

    const getIssues = useCallback(async (jql: string) => {
        if(siteId === ''){
            navigate(ROUTES.JIRA_OAUTH);
            return undefined;
        }
            
        const issues = await getJiraIssues(jql, jiraAccessToken, siteId, startDate, endDate);
        return await getIssuesEnrichedWithChangelog(jiraAccessToken, siteId, issues, calendarGrouping);
    }, [jiraAccessToken, siteId, startDate, endDate, calendarGrouping, navigate]);

    const getUserData = useCallback(async () => {
        const _userData = { ...userData, 
            github: { 
                ...userData.github as Record<string, GithubContributerResults>,
            }
        };
        
        await Promise.all(
            users.map(async ({ jiraUsername, githubUsername }) => {
                if(jiraUsername){
                    if(!userData.jira || !userData.jira[jiraUsername] || !userData.jira[jiraUsername].issues){
                        const jiraData = await getIssues(`assignee = ${jiraUsername}`);
                        if(typeof jiraData !== 'undefined'){
                            setUserData({ ...userData, jira: { [jiraUsername]: jiraData }});
                        }
                    }
                }
                
                if(githubUsername){
                    _userData.github[githubUsername] = await getContributorData(githubUsername, startDate, endDate);
                }
            })
        );

        setUserData(_userData);
    },[users, userData, startDate, endDate, setUserData, getIssues]);

    const getJiraData = useCallback(async () => {
        if(jiraProject){
            const jiraData = await getIssues(`project = ${jiraProject}`);
            if(typeof jiraData !== 'undefined'){
                setJiraData(jiraData);
            }
        }
    }, [jiraProject, getIssues]);

    const getRepoData = useCallback(async () => {
        const _repoData = await getReposByTag(repoTag, startDate, endDate);
        const repoDataMapped = await Promise.all(mapRepoResults(_repoData).map(async data => {
            return {
                ...data,
                codeAnalysis: await getCodeAnalysisResults(codeAnalysisFiles, data.name),
            }
        }));

        setRepoData(repoDataMapped);
    }, [repoTag, setRepoData, startDate, endDate, codeAnalysisFiles, getCodeAnalysisResults, getReposByTag]);

    const getData = useCallback(async () => {
        setIsLoading(true);

        await Promise.all([
            RENDER_USER_FORM && await getUserData(),
            await getJiraData(),
            await getRepoData(),
        ]);
        
        setIsLoading(false);
    }, [getRepoData, getUserData, getJiraData]);
    

    /*
    const getTreemapData = useCallback(() => {
        return getGithubTreemapData(userData.github);
    }, [userData]);
    */

    const renderUsers = useCallback(() => {
        return users.map((user, i) => (
            <InputsContainer key={i}>
                {Object.keys(FIELDS_TO_COLLECT).map(fieldKey => (
                    <TextField
                        onChange={e => setUsers(i, e.target.value, fieldKey)}
                        label={FIELDS_TO_COLLECT[fieldKey]}
                        value={user[fieldKey]}
                    />
                ))}
            </InputsContainer>
        ));
    }, [users, setUsers]);

    const renderGithubWorkflowInputs = useCallback(() => {
        if(repoData){
            const RepoWorkflowInputs = repoData.map(repo => {
                if(!repo.workflows){
                    return undefined;
                }

                return (<>
                      <Dropdown
                        label={repo.name}
                        options={repo.workflows?.map(({ name }) => ({ name, value: name }))}
                        onChange={(e) => setDeploymentWorkflow({...deploymentWorkflow, [repo.name]: e.target.value })}
                        value={deploymentWorkflow[repo.name]}
                    />
                </>)
            });

            return <InputsContainer>
                {RepoWorkflowInputs}
            </InputsContainer>
        }
    }, [deploymentWorkflow, repoData]);

    const renderGraphStatisticInputs = useCallback(() => {
        const displayGroupings: Record<string, any> = {
            'Pull Requests': GithubPRStats,
            'Security Vulnerabilities': GithubVulnerabilityStats,
            'Workflows': GithubWorkflowStats,
            'Code Analysis': CodeAnalysisStats,
            'Jira': JiraStats
        };

        return Object.keys(displayGroupings).map(displayGroupName  => {

            const InputSwitches = Object.keys(displayGroupings[displayGroupName]).map(key => (<Switch
                label={allStatistics[key as keyof typeof allStatistics]}
                checked={graphedStatistics.includes(key)}
                onChange={({ target }) => {
                    const _graphedStatistics = graphedStatistics;
                    if((target as unknown as Record<string, boolean>).checked){
                        _graphedStatistics.push(key);
                    }else{
                        _graphedStatistics.splice(_graphedStatistics.indexOf(key), 1);
                    }
                    setGraphedStatistics([..._graphedStatistics]);
                }}
            />));

            return (<>
                <h3>{displayGroupName}</h3>
                <InputsContainer>
                    {InputSwitches}
                </InputsContainer>
            </>);
        });
    }, [allStatistics, graphedStatistics]);

    const renderUserGraph = useCallback(() => {
        if(userData.github){
            return Object.keys(userData.github).map(username => {
                 const { calendar: calendarData, repo: repoData } = userData.github[username];
                 const calendarDataGrouped = mapContributionsCalendarToDateGrouping(calendarData, calendarGrouping);

                 const RepoGraph = getGraph(graphType, {
                    data: Object.keys(repoData).map(repo => ({ name: repo, ...repoData[repo].totals })).sort((a, b) => a.name.localeCompare(b.name)),
                    graphAxisData: [
                        {
                            dataKey: 'commits'
                        },
                        {
                            dataKey: 'prs'
                        }
                    ],
                    config: {
                        includeLegend: true,
                        includeTooltip: true,
                        syncId: 'githubDataRepo'
                    }
                });

                const CalendarGraph = getGraph(graphType, {
                    data: Object.keys(calendarDataGrouped).map(date => ({ name: date, ...calendarDataGrouped[date] })).sort((a, b) => a.name.localeCompare(b.name)),
                    graphAxisData: [
                        {
                            dataKey: 'contributions'
                        },
                    ],
                    config: {
                        includeLegend: true,
                        includeTooltip: true,
                        syncId: 'githubDataCalendar'
                    }
                });
                return (
                    <>
                        <h2>{username}</h2>
                        {RepoGraph}
                        {CalendarGraph}
                    </>
                )
            });
        }
        return null;
    }, [userData, graphType, calendarGrouping]);

    const renderJiraGraph = useCallback(() => {
        if(typeof jiraData !== 'undefined'){
            const jiraDataWithWip = enrichIssuesWithWipData(jiraData, calendarGrouping);

            return graphedStatistics.filter(graphedStatistic => JiraStats.hasOwnProperty(graphedStatistic)).map((graphedStatistic) => {
                let data = typeof jiraDataWithWip[graphedStatistic as keyof IssuesWithChangelogResponse] !== 'undefined' ? jiraDataWithWip[graphedStatistic as keyof IssuesWithChangelogResponse] : jiraDataWithWip.wipDates;

                const StatisticGraph = getGraph(graphType, {
                    data,
                    graphAxisData: [{ dataKey: graphedStatistic }],
                    config: {
                        includeLegend: true,
                        includeTooltip: true,
                        customLegendFormatter: (value: string, _entry: any) => {
                            return <span>{JiraStats[value as keyof typeof JiraStats]}</span>
                        },
                        customTooltipFormatter: (value: string, name: string, _props: any) => {
                            return [value, JiraStats[name as keyof typeof JiraStats]];
                        },
                        dataKeyFormatCallback: (value: string) => {
                            return getDisplayDateFromGrouping(value, calendarGrouping);
                        }
                    }
                });
    
                return StatisticGraph;
            });
        }
    }, [jiraData, graphedStatistics, calendarGrouping, graphType]);

    const renderReposGraph = useCallback(() => {
        if(repoData){
            return graphedStatistics.map(graphedStatistic => {
                
                let repoDataField = 'pullRequests' as keyof GithubRepoResult['pullRequests'];
                let repoDataFunction = mapRepoPullRequestsByDate;
                if(GithubVulnerabilityStats.hasOwnProperty(graphedStatistic)){
                    repoDataField = 'vulnerabilities' as keyof GithubRepoResult['vulnerabilities'];
                    repoDataFunction = mapRepoVulnerabilitiesByDate;
                }else if(GithubWorkflowStats.hasOwnProperty(graphedStatistic)){
                    repoDataField = 'workflows' as keyof GithubRepoResult['workflows'];
                    repoDataFunction = mapRepoWorkflowsByDate;
                }else if (CodeAnalysisStats.hasOwnProperty(graphedStatistic)){
                    repoDataField = 'codeAnalysis' as keyof GithubRepoResult['codeAnalysis'];
                    repoDataFunction = mapRepoCodeAnalysisByDate;
                }else if(JiraStats.hasOwnProperty(graphedStatistic)){
                    // TODO: add data graphing here or remove from object 
                    return <></>;
                }

                let repoDataFiltered = repoData.filter(repo => (repo[repoDataField as keyof GithubRepoResult] as Array<any>)?.length);
                
                // have to filter workflows an additional time
                if(repoDataField === 'workflows' as keyof GithubRepoResult['workflows']){
                    repoDataFiltered = filterRepoWorkflows(repoDataFiltered, deploymentWorkflow) as any;
                }
                
                const graphData = repoDataFunction(repoDataFiltered, calendarGrouping);

                const StatisticGraph = getGraph(graphType, {
                    data: graphData,
                    graphAxisData: repoDataFiltered.map(repo => ({ dataKey: `${repo.name}|${graphedStatistic}` } as any)),
                    config: {
                        includeLegend: true,
                        includeTooltip: true,
                        customLegendFormatter: (value: string, _entry: any) => {
                            return <span>{value.split('|')[0]}</span>
                        },
                        customTooltipFormatter: (value: string, name: string, _props: any) => {
                            return [value, name.split('|')[0]];
                        },
                        dataKeyFormatCallback: (value: string) => {
                            return getDisplayDateFromGrouping(value, calendarGrouping);
                        }
                    }
                });

                return (<GraphContainer>
                    <h3>{allStatistics[graphedStatistic as keyof typeof allStatistics]}</h3>
                    {StatisticGraph}
                </GraphContainer>);
            });
        }
    }, [repoData, graphType, graphedStatistics, calendarGrouping, allStatistics, deploymentWorkflow]);

    const getLoader = () => (
        <LoaderDoodleContainer>
            <AsurionDoodleSpinner
                text="Loading Data..."
                title="Loading Data..."
                width="60px"
            />
        </LoaderDoodleContainer>
    );

    return (
        <Container>
            {RENDER_USER_FORM && renderUsers() && (
                <Button onClick={() => {
                    const _users = users;
                    _users.push({ jiraUsername: '', githubUsername: '' });
                    _setUsers([..._users]);
                }}>Add User</Button>
            )}
            <InputsContainer>
                <TextField
                    label="Platform Tag"
                    onChange={e => setRepoTag(e.target.value)}
                    value={repoTag}
                />
                <TextField
                    label="Jira Project"
                    onChange={e => setJiraProject(e.target.value)}
                    value={jiraProject}
                />
                <Datepicker
                    value={startDate}
                    onChange={(newDate: Date) => setStartDate(newDate)}
                    textFieldProps={{
                        label: 'Start Date',
                    }}
                />
                <Datepicker
                    value={endDate}
                    onChange={(newDate: Date) => setEndDate(newDate)}
                    textFieldProps={{
                        label: 'End Date',
                    }}
                />
                <Dropdown
                    label="Graph Type"
                    options={Object.keys(GraphType).map((key) => ({ name: key, value: (GraphType as any)[key]}))}
                    onChange={(e) => setGraphType(e.target.value)}
                    value={graphType}
                />
                <Dropdown
                    label="Calendar Grouping"
                    options={Object.keys(CalendarDateUnit).map((key) => ({ name: key, value: (CalendarDateUnit as any)[key]}))}
                    onChange={(e) => setCalendarGrouping(e.target.value)}
                    value={calendarGrouping}
                />
            </InputsContainer>
            <h3>Github Deployment Workflows</h3>
            {renderGithubWorkflowInputs()}

            <h2>Graphed Metrics</h2>
            {renderGraphStatisticInputs()}

            {(isLoading && getLoader()) || 
            (<>
                <Button onClick={getData}>Get Stats</Button>
                
                {RENDER_USER_FORM && renderUserGraph()}
                
                <GraphsContainer>
                    {renderJiraGraph()}
                </GraphsContainer>
                
                <GraphsContainer>
                    {renderReposGraph()}
                </GraphsContainer>
            </>)
            }
            
        </Container>
    );
};

const wrapWithQueryProvider = (Element: React.FC) => {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <Element />
        </QueryClientProvider>
    );
};

const DevStatsComponentWrapped = () => wrapWithQueryProvider(DevStats);

export default DevStatsComponentWrapped;