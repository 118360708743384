import { Octokit } from 'octokit';
import { graphql } from '@octokit/graphql';
import { GithubRepoResult } from '../../types';
import { getDiffFromCurrentDate, getFormattedDate } from '../../utils';
import { mapGraphQLResultsByRepo } from './mappers';

let GITHUB_TOKEN = '';
const DEFAULT_ORG_NAME = 'Soluto-Private';

export const setGithubToken = (token: string) => GITHUB_TOKEN = token;

const graphqlWithAuth = () => graphql.defaults({
    headers: {
      authorization: `token ${GITHUB_TOKEN}`,
    },
});

export const getContributorData = async (username: string, startDate = getDiffFromCurrentDate(-90), endDate = new Date()) => {
    const results: Record<string, any> = await graphqlWithAuth()(`
    query ContributionsView($username: String!, $from: DateTime!, $to: DateTime!) {
        user(login: $username) {
          contributionsCollection(from: $from, to: $to) {
            commitContributionsByRepository{    
                contributions(first: 100) {
                    totalCount
                    nodes {
                        commitCount
                        occurredAt
                    }
                }
                repository {
                    name
                }
            }
            pullRequestContributionsByRepository {
                contributions(first: 100) {
                    totalCount
                    nodes {
                        occurredAt
                    }
                }
                repository {
                    name
                }
            }
            contributionCalendar {
                totalContributions
                weeks {
                  contributionDays {
                    contributionCount
                    date
                  }
                }
            }
          }
        }
      }
    `, {
        username,
        from: startDate.toISOString(),
        to: endDate.toISOString(),
    });

    return {
        repo: mapGraphQLResultsByRepo(results.user.contributionsCollection.commitContributionsByRepository, 'commits', mapGraphQLResultsByRepo(results.user.contributionsCollection.pullRequestContributionsByRepository, 'prs')),
        calendar: results.user.contributionsCollection.contributionCalendar,
    };
};

export const getReposByTag = async (tag: string, startDate = getDiffFromCurrentDate(-90), endDate = new Date(), orgName: string = DEFAULT_ORG_NAME): Promise<GithubRepoResult[]> => {
    const results: Record<string, any> = await (await graphqlWithAuth())(`
    query ($queryString: String!, $numberOfRepos:Int!) {
        search(query: $queryString, type: REPOSITORY, first: $numberOfRepos) {
          repositoryCount
          edges {
            node {
              ... on Repository {
                name
                url
                description
                primaryLanguage {name}
                languages(first: 3) { nodes {name} }
                issues {totalCount}
                createdAt
                pushedAt
                updatedAt
                defaultBranchRef{
                    name
                }
              }
            }
          }
        }
      }      
    `, {
        queryString: `topic:${tag} org:${orgName}`, 
        numberOfRepos: 50 
    });

    return await Promise.all(results.search.edges.map(async ({ node }: Record<string, any>) => ({
        ...node,
        pullRequests: await getPullRequests(node.name, startDate, endDate, orgName),
        vulnerabilities: await getVulnerabilities(node.name, startDate, endDate, orgName),
        workflows: await getWorkflows(node.name, node.defaultBranchRef.name, startDate, endDate, orgName),
    })));
};

export const getVulnerabilities = async (repoName: string, startDate = getDiffFromCurrentDate(-90), endDate = new Date(), orgName: string = DEFAULT_ORG_NAME, endCursor = undefined, prevResults: Record<string,any>[] = []) => {
    const results: Record<string, any> = await (await graphqlWithAuth())(`
    query vulnerabilities($repoName:String!, $repoOwner:String!, $endCursor:String) {
        repository(name: $repoName, owner: $repoOwner) {
            vulnerabilityAlerts(first: 100, after: $endCursor) {
                pageInfo {
                    hasNextPage
                    endCursor
                }
                nodes {
                    createdAt
                    dismissedAt
                    fixedAt
                    state
                }
            }
        }
      }     
    `, {
        repoName,
        repoOwner: orgName,
        endCursor,
    });

    const mergedResults = [
        ...prevResults, 
        ...results.repository.vulnerabilityAlerts.nodes.filter((alert: Record<string, string>) => new Date(alert.createdAt) >= startDate && new Date(alert.createdAt) <= endDate)
    ];

    if(results.repository.vulnerabilityAlerts.pageInfo.hasNextPage){
        await getVulnerabilities(repoName, startDate, endDate, orgName, results.repository.vulnerabilityAlerts.pageInfo.endCursor, mergedResults);
    }

    return mergedResults;
};


export const getPullRequests = async (repoName: string, startDate = getDiffFromCurrentDate(-90), endDate = new Date(), orgName: string = DEFAULT_ORG_NAME, endCursor = undefined, prevResults: Record<string,any>[] = []) => {
    const results: Record<string, any> = await (await graphqlWithAuth())(`
    query pullRequest($queryString:String!, $endCursor:String) {
        search(query: $queryString, type: ISSUE, first: 100, after: $endCursor) {
            pageInfo {
                hasNextPage
                endCursor
            }
            nodes {
                ... on PullRequest {
                    title
                    createdAt
                    mergedAt
                    totalCommentsCount
                    changedFiles
                    additions
                    deletions
                }
            }
          
        }
      }     
    `, {
        queryString: `repo:${orgName}/${repoName} is:pr is:merged merged:${startDate.toISOString()}..${endDate.toISOString()}`,
        endCursor,
    });

    const mergedResults = [...prevResults, ...results.search.nodes];

    if(results.search.pageInfo.hasNextPage){
        await getPullRequests(repoName, startDate, endDate, orgName, results.search.pageInfo.endCursor, mergedResults);
    }

    return mergedResults;
};

export const getWorkflows = async (repoName: string, defaultBranch: string, startDate = getDiffFromCurrentDate(-90), endDate = new Date(), orgName: string = DEFAULT_ORG_NAME) => {
    const results: Record<string, any> = await (await graphqlWithAuth())(`
    query workflows($repoName:String!, $workflowsRevParse:String!, $repoOwner:String!) {
        repository(name: $repoName, owner: $repoOwner) {
            object(expression: $workflowsRevParse){
                ... on Tree {
                    entries {
                        name
                        object {
                            ... on Blob {
                                byteSize
                                #text
                            }
                        }
                    }
                }
            }
        }
    }
    `, {
        repoName,
        workflowsRevParse: `${defaultBranch}:.github/workflows/`,
        repoOwner: orgName,
    });

    if(!results.repository.object?.entries){
        return null;
    }

    const workflows = await Promise.all(results.repository.object.entries.map(async ({ name }: { name: string }) => {
        return {
            name,
            runs: await getWorkflowRunsRest(repoName, name, startDate, endDate, orgName),
        }
    }));
    
    return workflows.filter(i => typeof i.runs !== 'undefined');
};

/*
export const getWorkflowRuns = async (repoName: string, workflowFilename: string, orgName: string = DEFAULT_ORG_NAME, endCursor = undefined, prevResults: Record<string,any>[] = []) => {
    const workflowId = await getWorkflowIdFromFilename(workflowFilename, repoName);
    
    const results: Record<string, any> = await (await graphqlWithAuth())(`
    query workflowRuns($repoName:String!, $repoOwner:String!, $workflowId:String!, $endCursor:String) {
        repository(name: $repoName, owner: $repoOwner) {
            nodes {
                node(id: $workflowId) {
                  ... on Workflow {
                    runs(first: 100, after: $endCursor) {
                      nodes {
                        runNumber
                        createdAt
                        checkSuite {
                          commit {
                            message
                            associatedPullRequests(first: 1) {
                              nodes {
                                number
                              }
                            }
                            history {
                              nodes {
                                author {
                                  date
                                  email
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
            }
        }
      }     
    `, {
        repoName,
        repoOwner: orgName,
        endCursor,
        workflowId
    });

    console.log('---- WORKFLOW RESULTS ----', results);

    const mergedResults = [...prevResults, ...results.repository.nodes];

    if(results.repository.pageInfo.hasNextPage){
        await getWorkflowRuns(repoName, workflowFilename, orgName, results.search.pageInfo.endCursor, mergedResults);
    }

    return mergedResults;
};
*/

// export hooks 
export const useGetReposByTag = () => ({
    getReposByTag 
});

/*
*   REST API calls 
*   only used for getting workflowIds since they're not available from the GraphQL API
*/

const getOctokit = () => new Octokit({
    auth: GITHUB_TOKEN
});

const getWorkflowRunsRest = async (repo: string, filename: string, startDate = getDiffFromCurrentDate(-90), endDate = new Date(), orgName: string = DEFAULT_ORG_NAME) => {
    try{
        const results = await (getOctokit()).request('GET /repos/{owner}/{repo}/actions/workflows/{workflow_id}/runs', {
            owner: orgName,
            repo,
            workflow_id: filename,
            per_page: 100,
            created: `${getFormattedDate(startDate)}..${getFormattedDate(endDate)}`,
            headers: {
            'X-GitHub-Api-Version': '2022-11-28'
            }
        });

        return results.data.workflow_runs;
    }catch(e){}

    return undefined;
};
