export enum CodeAnalysisStats {
    LINES_OF_CODE = 'Lines of Code',
    LINES_OF_COMMENTS = 'Lines of Comments',
    COMPLEXITY_RATING = 'Complexity Rating',
    COUNT_OF_FILES = 'Files',
};
export type CodeAnalysisStatsKeys = keyof typeof CodeAnalysisStats;

export type CodeAnalysisResult = {
    Blank: number;
    Bytes: number;
    Code: number;
    CodeBytes: number;
    Comment: number;
    Complexity: number;
    Count: number;
    Files: Record<string, any>[];
    Lines: number;
    Name: string;
    WeightedComplexity: number;
    createdAt?: string;
};