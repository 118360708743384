import { GraphQLContributerResults, GithubContributerResults, GithubRepoResult, CalendarDateUnit, GithubPRStatsKeys, GithubVulnerabilityStatsKeys, GithubWorkflowStatsKeys } from "../../types";
import { getDateFromGrouping, secondsToDays } from "../../utils";

const DECIMAL_PRECISION = 1;

export const mapGraphQLResultsByRepo = (results: GraphQLContributerResults[], field: 'commits' | 'prs' = 'commits', mappedResults: GithubContributerResults['repo'] = {}): GithubContributerResults['repo'] => {
    results.forEach((result) => {
        if(!mappedResults[result.repository.name]){
            mappedResults[result.repository.name] = {
                totals: {
                    commits: 0,
                    prs: 0,
                },
                byDate: {}
            };
        }
        mappedResults[result.repository.name].totals[field] += result.contributions.totalCount as number;
    });
    return mappedResults;
};

export const mapRepoResults = (results: GithubRepoResult[]) => {
    return results.map((repoData) => {
        if(!repoData.pullRequests || !repoData.pullRequests.length){
            return {
                ...repoData
            };
        }

        return {
            ...repoData,
            ...getAveragesFromPRs(repoData.pullRequests),
        };
    });
};

export const mapRepoPullRequestsByDate = (results: GithubRepoResult[], dateGrouping = CalendarDateUnit.MONTH) => {
    return mapRepoDataByDate(
        results, 
        'pullRequests', 
        (record: GithubRepoResult['pullRequests'][0], dateGrouping) => getDateFromGrouping(record.createdAt, dateGrouping), 
        getAveragesFromPRs, 
        dateGrouping
    );
};

export const mapRepoVulnerabilitiesByDate = (results: GithubRepoResult[], dateGrouping = CalendarDateUnit.MONTH) => {
    return mapRepoDataByDate(
        results, 
        'vulnerabilities', 
        (record: GithubRepoResult['vulnerabilities'][0], dateGrouping) => getDateFromGrouping(record.createdAt, dateGrouping), 
        getAveragesFromVulnerabilites, 
        dateGrouping
    );
};

export const mapRepoWorkflowsByDate = (results: GithubRepoResult[], dateGrouping = CalendarDateUnit.MONTH) => {
    return mapRepoDataByDate(
        results, 
        'workflows', 
        (record, dateGrouping) => getDateFromGrouping((record as Record<string, any>).run_started_at, dateGrouping),
        getAveragesFromWorkflows, 
        dateGrouping
    );
};

type RepoDataRecords = Pick<GithubRepoResult, 'pullRequests' | 'vulnerabilities' | 'workflows' | 'codeAnalysis'>;
type RepoDataRecordsKeys = keyof RepoDataRecords;
export const mapRepoDataByDate = <T = Record<string, any>>(
    results: GithubRepoResult[],
    mappingField: RepoDataRecordsKeys,
    getDateCallback: (_record: Required<RepoDataRecords>[RepoDataRecordsKeys][number], _dateGrouping: CalendarDateUnit) => string, 
    getAveragesCallback: (mappedRecords: T[]) => Record<string, any>, 
    dateGrouping = CalendarDateUnit.MONTH
) => {
    const mappedResults: Record<string, Record<string, any>> = {};
    results.forEach((repoData) => {
        if(!repoData[mappingField] || !repoData[mappingField]?.length){
            return false;
        }

        repoData[mappingField]?.forEach((record) => {
            const date = getDateCallback(record, dateGrouping);
            if(!mappedResults[date]){
                mappedResults[date] = {};
            }
            if(!mappedResults[date][repoData.name]){
                mappedResults[date][repoData.name] = [];
            }
            mappedResults[date][repoData.name].push(record);
        });
    });

    return Object.keys(mappedResults).map(date => {
        const repoRecordData: Record<string, string | number | undefined> = {};
        Object.keys(mappedResults[date]).forEach(repo => {
            const repoRecordAverages = getAveragesCallback(mappedResults[date][repo]);
            Object.keys(repoRecordAverages).forEach(avgField => {
                repoRecordData[`${repo}|${avgField}`] = repoRecordAverages[avgField];
            });
        });

        return {
            name: date,
            ...repoRecordData,
        }
    }).sort((a, b) => new Date(a.name).getTime() - new Date(b.name).getTime() );
}

const getAveragesFromPRs = (pullRequests: Record<string, any>[]): Record<GithubPRStatsKeys, number> => {
    const numericPRTotals = pullRequests.reduce((totals: Record<GithubPRStatsKeys, number>, pr: Record<string, any>) => {
        if(pr.mergedAt){
            const timeToMerge = (new Date(pr.mergedAt).getTime() - new Date(pr.createdAt).getTime());
            totals.AVERAGE_TIME_TO_MERGE += timeToMerge;

            if(!totals.MIN_TIME_TO_MERGE || totals.MIN_TIME_TO_MERGE > timeToMerge){
                totals.MIN_TIME_TO_MERGE = timeToMerge;
            }

            if(!totals.MAX_TIME_TO_MERGE || totals.MAX_TIME_TO_MERGE < timeToMerge){
                totals.MAX_TIME_TO_MERGE = timeToMerge;
            }
        }

        totals.AVERAGE_COMMENT_COUNT += pr.totalCommentsCount
        totals.AVERAGE_CHANGED_FILES += pr.changedFiles;

        if(!totals.MAX_CHANGED_FILES || totals.MAX_CHANGED_FILES < pr.changedFiles){
            totals.MAX_CHANGED_FILES = pr.changedFiles;
        }
        if(!totals.MIN_CHANGED_FILES || totals.MIN_CHANGED_FILES > pr.changedFiles){
            totals.MIN_CHANGED_FILES = pr.changedFiles;
        }

        return totals;
    }, {
        MIN_TIME_TO_MERGE: undefined,
        MAX_TIME_TO_MERGE: 0,
        AVERAGE_TIME_TO_MERGE: 0,
        AVERAGE_COMMENT_COUNT: 0,
        MIN_CHANGED_FILES: 0,
        MAX_CHANGED_FILES: 0,
        AVERAGE_CHANGED_FILES: 0,
    });

    const averageTimeToMerge = numericPRTotals.AVERAGE_TIME_TO_MERGE / pullRequests.filter(pr => pr.mergedAt).length;

    return {
        MIN_TIME_TO_MERGE: parseFloat(secondsToDays(numericPRTotals.MIN_TIME_TO_MERGE / 1000).toFixed(DECIMAL_PRECISION)),
        MAX_TIME_TO_MERGE: parseFloat(secondsToDays(numericPRTotals.MAX_TIME_TO_MERGE / 1000).toFixed(DECIMAL_PRECISION)),
        AVERAGE_TIME_TO_MERGE: parseFloat(secondsToDays(averageTimeToMerge / 1000).toFixed(DECIMAL_PRECISION)),
        AVERAGE_COMMENT_COUNT: parseFloat(( numericPRTotals.AVERAGE_COMMENT_COUNT / pullRequests.length ).toFixed(DECIMAL_PRECISION)),
        AVERAGE_CHANGED_FILES: parseFloat(( numericPRTotals.AVERAGE_CHANGED_FILES / pullRequests.length ).toFixed(DECIMAL_PRECISION)),
        MAX_CHANGED_FILES: numericPRTotals.MAX_CHANGED_FILES,
        MIN_CHANGED_FILES: numericPRTotals.MIN_CHANGED_FILES,
    };
};

const getAveragesFromVulnerabilites = (vulnerabilities: Record<string, any>[]): Record<GithubVulnerabilityStatsKeys, number | undefined> => {
    const averageTimeToResolve = vulnerabilities.reduce((total: number, vuln: Record<string, any>) => {
        if(vuln.fixedAt){
            return total + (new Date(vuln.fixedAt).getTime() - new Date(vuln.createdAt).getTime());
        }
        return total;
    }, 0) / vulnerabilities.filter(vuln => vuln.fixedAt).length;

    const totalOpenVulnerabilities = vulnerabilities.filter(vuln => vuln.state === 'OPEN').length;
    const totalFixedVulnerabilities = vulnerabilities.filter(vuln => vuln.state === 'FIXED').length;

    return {
        TOTAL_OPEN_VULNERABILITIES: totalOpenVulnerabilities,
        TOTAL_FIXED_VULNERABILITIES: totalFixedVulnerabilities,
        AVERAGE_TIME_TO_RESOLVE: averageTimeToResolve ? parseFloat(secondsToDays(averageTimeToResolve / 1000).toFixed(DECIMAL_PRECISION)) : undefined,
    };
};

export const filterRepoWorkflows = (repoData: GithubRepoResult[], repoWorkflowNames: Record<GithubRepoResult['name'], string>) => {
    return repoData.map(repo => {
        return {
            ...repo,
            workflows: (repo.workflows?.filter(({ name }) => name === repoWorkflowNames[repo.name])[0])?.runs
        };
    });
};

export const getAveragesFromWorkflows = (workflows: Required<GithubRepoResult>['workflows'][0]['runs']): Record<GithubWorkflowStatsKeys, number | undefined> => {
    const avgRunDuration = workflows.reduce((total: number, run: Record<string, any>) => {
        return total + (new Date(run.updated_at).getTime() - new Date(run.run_started_at).getTime());
    }, 0) / workflows.length;

    const totalSuccessfulRuns = workflows.filter(run => run.conclusion === 'successful').length;
    const totalFailedRuns = workflows.filter(run => run.conclusion === 'failure').length;

    return {
        // formatted to minutes 
        AVERAGE_DEPLOYMENT_DURATION: parseFloat((avgRunDuration / 1000 / 60).toFixed(DECIMAL_PRECISION)),
        TOTAL_FAILED_DEPLOYMENTS: totalFailedRuns,
        TOTAL_SUCCESSFUL_DEPLOYMENTS: totalSuccessfulRuns,
        DEPLOYMENT_SUCCESS_PERCENTAGE: totalSuccessfulRuns / workflows.length,
    };
};
