export enum JiraStatuses {
    BLOCKED = 'BLOCKED',
    PARKED = 'PARKED',
    IN_PROGRESS = 'In Progress',
    CODE_REVIEW = 'Code Review',
    MERGED = 'Merged and Testing',
    DONE = 'Done'
};

export enum JiraStats {
    AVG_DAYS_IN_PROGRESS = 'Average Days in Progress',
    DELIVERY_LEAD_TIME = 'Delivery Lead Time (Days)',
    AVG_WIP = 'Average Work In Progress (WIP)',
    MAX_WIP = 'Max Work In Progress (WIP)',
    MIN_WIP = 'Min Work In Progress (WIP)'
};

export type IssuesWithChangelogResponse = {
    issues: Record<string, any>[];
    wipDates?: Record<string, any>;
    AVG_DAYS_IN_PROGRESS: Record<string, any>[];
    DELIVERY_LEAD_TIME: Record<string, any>[];
};
