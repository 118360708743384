import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router';
import { ROUTES } from '../routes';

export const LoginPage = () => {
    const navigate = useNavigate();
    const auth = useAuth();

    useEffect(() => {
        const checkIfUserExists = async () => {
            if (auth.isAuthenticated) {
                navigate(ROUTES.DASHBOARD);
            } else {
                await auth.signinRedirect();
            }
        };

        checkIfUserExists(); // eslint-disable-line
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>You are being redirected to SSO.</div>
    );
};

export default LoginPage;
