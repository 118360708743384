import {
    useQuery
} from '@tanstack/react-query'
import { CodeAnalysisResult } from '../../types';

const getCodeAnalysisResults = async (allFiles: string[], repoName: string): Promise<CodeAnalysisResult[] | undefined> => {
    try{
        const repoFiles = allFiles?.filter(fileName => fileName.match(new RegExp(`^${repoName}\\.[\\w-_]+\\.json$`)));

        if(typeof repoFiles !== 'undefined'){
            const results = await Promise.all(repoFiles.flatMap(async (fileName: string) => {
                return (await getJSONFile(fileName)).flatMap(result => ({
                    ...result,
                    createdAt: new Date(fileName.split('.')[1].replace(/_(\d{2})-(\d{2})-(\d{2})$/, 'T$1:$2:$3')).toISOString()
                }));
            }));
            return results.flatMap(r => r);
        }
    }catch(e){
        console.log(e);
    }

    return undefined;
};

export const useGetCodeAnalysisResults = () => {
    const queryResults = useQuery({ queryKey: ['code-analysis-all-files'], queryFn: getAllCodeAnalysisFiles });
    return {
        ...queryResults,
        getCodeAnalysisResults: async (data: string[] | undefined, _repoName: string) => await getCodeAnalysisResults(data!, _repoName)
    };
};

// TODO: refactor to use jotai 
export const getAllCodeAnalysisFiles = async (): Promise<string[]> => {
    const results = await fetch(`https://${process.env.API_PUBLIC_URI}/files`, {
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.API_GATEWAY_API_KEY!
        }
    });

    return (await results.json()).files;
}

const getJSONFile = async (fileName: string): Promise<CodeAnalysisResult[]> => {
    const results = await fetch(`https://code-analysis.solutodev.com/${fileName}`, {
        headers: {
            Accept: 'application/json',
        }
    });

    return await results.json();
};
