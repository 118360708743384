import { CalendarDateUnit, IssuesWithChangelogResponse, JiraStatuses } from "../../types";
import { addDateUnitToDate, formatGroupedDateData, getDateFromGrouping, getDateRangeFromDateDiff } from "../../utils";
import { getIssueChangelog } from "./jiraAPI";

const DECIMAL_PRECISION = 2;

export const getMaintenanceIssues = (issues: Record<string, any>[]) => {
    return issues.filter(issue => issue.fields.issuetype.name === "Maintenance");
};

export const getDistinctIssueTypes = (issues: Record<string, any>[]) => {
    const issueTypes: string[] = [];
    issues.forEach((issue) => {
        if(!issueTypes.includes(issue.fields.issuetype.name)){
            issueTypes.push(issue.fields.issuetype.name);
        }
    });
    return issueTypes;
};

type DatesInStatus = {
    [status in JiraStatuses]?: {
        startDate?: Date;
        endDate?: Date;
    };
};
export const getDatesInStatus = (changelog: Record<string, any>[], status: JiraStatuses[]): DatesInStatus => {
    const statuses: DatesInStatus = status.reduce((acc,status) => (acc[status]={}, acc), {} as DatesInStatus);

    changelog.forEach(item => {
        const itemStatus = item.items.find((itemValues: Record<string, string>) => itemValues.fieldId === "status");
        if(itemStatus){
            if(status.includes(itemStatus.toString)){
                // @ts-ignore
                statuses[itemStatus.toString as JiraStatuses].startDate = new Date(item.created);
            }else if(status.includes(itemStatus.fromString)){
                // @ts-ignore
                statuses[itemStatus.fromString as JiraStatuses].endDate = new Date(item.created);
            }
        }
    });

    return statuses;
};

export const getTimeInStatuses = (statusDates: DatesInStatus) => {
    let totalTime = 0;

    Object.values(statusDates).forEach(({ startDate, endDate }) => {
        if(typeof startDate !== 'undefined' && typeof endDate !== 'undefined'){
            totalTime += (endDate.getTime() - startDate.getTime()) / 1000;
        }
    });

    return totalTime;
};

export const getIssuesEnrichedWithChangelog = async (accessToken: string, siteId: string, issues: Record<string, any>[], calendarGrouping = CalendarDateUnit.MONTH): Promise<IssuesWithChangelogResponse> => {
    let totalTimeInProgress = 0;
    let totalDeliveryLeadTime = 0;
    const deliveryLeadTimeGroupedByDate: Record<string, any> = {};

    const issuesEnriched = await Promise.all(issues.map(async issue => {
        const changelog = await getIssueChangelog(accessToken, siteId, issue.id);
        const datesInProgress = getDatesInStatus(changelog.values, [JiraStatuses.IN_PROGRESS]);
        const timeInProgress = getTimeInStatuses(datesInProgress);

        totalTimeInProgress += timeInProgress;

        // Calculate Delivery Lead Time
        const datesForDelieveryLeadTime = getDatesInStatus(changelog.values, Object.values(JiraStatuses));
        let deliveryLeadTime = undefined;
        if(typeof datesForDelieveryLeadTime[JiraStatuses.DONE]?.startDate !== 'undefined' && typeof datesForDelieveryLeadTime[JiraStatuses.IN_PROGRESS]?.startDate !== 'undefined'){
            
            deliveryLeadTime = (datesForDelieveryLeadTime[JiraStatuses.DONE]?.startDate?.getTime()! - datesForDelieveryLeadTime[JiraStatuses.IN_PROGRESS]?.startDate?.getTime()!) / 1000;
            totalDeliveryLeadTime += deliveryLeadTime;

            const endDate = getDateFromGrouping(datesForDelieveryLeadTime[JiraStatuses.DONE]?.startDate?.toISOString(), calendarGrouping);

            if(typeof deliveryLeadTimeGroupedByDate[endDate] === 'undefined'){
                deliveryLeadTimeGroupedByDate[endDate] = {
                    DELIVERY_LEAD_TIME: 0,
                    TOTAL_DELIVERY_LEAD_TIME: 0,
                    TOTAL_COUNT: 0
                };
            }
            deliveryLeadTimeGroupedByDate[endDate].TOTAL_COUNT++;
            deliveryLeadTimeGroupedByDate[endDate].TOTAL_DELIVERY_LEAD_TIME += deliveryLeadTime;
            deliveryLeadTimeGroupedByDate[endDate].DELIVERY_LEAD_TIME = (deliveryLeadTimeGroupedByDate[endDate].TOTAL_DELIVERY_LEAD_TIME / deliveryLeadTimeGroupedByDate[endDate].TOTAL_COUNT) / (60 * 60 * 24);
            deliveryLeadTimeGroupedByDate[endDate].DELIVERY_LEAD_TIME =  deliveryLeadTimeGroupedByDate[endDate].DELIVERY_LEAD_TIME.toFixed(DECIMAL_PRECISION);
        }

        return { 
            ...issue,
            timeInProgress,
            deliveryLeadTime
        };
    }));
    
    const avgDaysInProgress = Math.abs(totalTimeInProgress / issuesEnriched.length) / (60 * 60 * 24);
    //const avgDeliveryLeadTime = Math.abs(totalDeliveryLeadTime / issuesEnriched.filter(i => typeof i.deliveryLeadTime !== 'undefined').length) / (60 * 60 * 24);

    return {
        issues: issuesEnriched,
        AVG_DAYS_IN_PROGRESS: [{ AVG_DAYS_IN_PROGRESS: avgDaysInProgress.toFixed(DECIMAL_PRECISION), name: 'Analytic'}],
        DELIVERY_LEAD_TIME: formatGroupedDateData(deliveryLeadTimeGroupedByDate)
    }
};

export const enrichIssuesWithWipData = (issuesData: IssuesWithChangelogResponse, dateGrouping = CalendarDateUnit.MONTH) => {
    const issuesGroupedByDate: Record<string, any> = {};
    const wipDates: Record<string, number> = {};
    issuesData.issues.forEach((issue: Record<string, any>) => {
        const date = getDateFromGrouping(issue.startDate, dateGrouping);

        if(date){
            if(typeof issuesGroupedByDate[date] === 'undefined'){
                issuesGroupedByDate[date] = [];   
            }
            issuesGroupedByDate[date].push(issue);

            if(issue.startDate && issue.endDate){
                const dateDiffRange = getDateRangeFromDateDiff(issue.startDate, issue.endDate, CalendarDateUnit.DAY);
                for(let i = 0; i < dateDiffRange; i++){
                    const wipDate = addDateUnitToDate(issue.startDate, dateGrouping, i);
                    const wipDateString = getDateFromGrouping(wipDate.toISOString(), CalendarDateUnit.DAY);
                    if(typeof wipDates[wipDateString] === 'undefined'){
                        wipDates[wipDateString] = 0;
                    }
                    wipDates[wipDateString]++;
                }
            }
        }
    });

    const _wipDataByDate = formatWipData(wipDates, dateGrouping);
    const wipDataFormatted = Object.keys(_wipDataByDate).map(date => {
        return {
            ..._wipDataByDate[date],
            name: date
        }
    }).sort((a, b) => new Date(a.name).getTime() - new Date(b.name).getTime());
    
    return {
        ...issuesData,
        issuesGroupedByDate,
        wipDates: wipDataFormatted,
    };
};

const formatWipData = (wipDataByDay: Record<string, number>, dateGrouping: CalendarDateUnit) => {
    const mappedResults: Record<string, any> = {};
    Object.keys(wipDataByDay).forEach(_date => {
        const date = getDateFromGrouping(_date, dateGrouping);
        if(!mappedResults[date]){
            mappedResults[date] = {
                total: 0,
                count: 0,
                AVG_WIP: 0,
                MAX_WIP: 0,
                MIN_WIP: 0,
            };
        }

        mappedResults[date].count++;
        mappedResults[date].total += wipDataByDay[_date];

        if(mappedResults[date].MAX_WIP === 0 || mappedResults[date].MAX_WIP < wipDataByDay[_date]){
            mappedResults[date].MAX_WIP = wipDataByDay[_date];
        }
        if(mappedResults[date].MIN_WIP === 0 || mappedResults[date].MIN_WIP > wipDataByDay[_date]){
            mappedResults[date].MIN_WIP = wipDataByDay[_date];
        }
    });

    const mappedResultsWithAvg: Record<string, any> = {};
    Object.keys(mappedResults).forEach(date => {
        if(typeof mappedResultsWithAvg[date] === 'undefined'){
            mappedResultsWithAvg[date] = mappedResults[date];
        }

        mappedResultsWithAvg[date].AVG_WIP = mappedResultsWithAvg[date].total / mappedResultsWithAvg[date].count;
    });

    return mappedResultsWithAvg;
};
