import { CalendarDateUnit } from "../types";

export const getFormattedDate = (date: Date) => {
    return date.toISOString().split('T')[0];
};

export const getDiffFromCurrentDate = (diff: number) => {
    const date = new Date();
    date.setDate(date.getDate() + diff);
    return date;
};

export const getDateFromGrouping = (date?: string, grouping: CalendarDateUnit = CalendarDateUnit.WEEK) => {
    if(!date){
        return "";
    }

    const _date = new Date(date);

    switch(grouping){
        case CalendarDateUnit.DAY:
            return `${_date.getFullYear()}-${_date.getMonth() + 1}-${_date.getDate()}`;

        case CalendarDateUnit.WEEK:
            return `${_date.getFullYear()}-${_date.getMonth() + 1}-${Math.ceil(_date.getDate()/7)}`;
        
        case CalendarDateUnit.MONTH:
            return `${_date.getFullYear()}-${_date.getMonth() + 1}`;
    
    }
};

export const daysToSeconds = (days: number) => {
    return days * 86400;
};

export const secondsToDays = (seconds: number) => {
    return seconds / 86400;
};

export const secondsToWeeks = (seconds: number) => {
    return secondsToDays(seconds) / 7;
};

export const secondsToMonths = (seconds: number) => {
    return secondsToWeeks(seconds) / 4;
};

export const getDateRangeFromDateDiff = (startDate: Date, endDate: Date, grouping: CalendarDateUnit = CalendarDateUnit.WEEK) => {
    const diffInSeconds = (endDate.getTime() - startDate.getTime()) / 1000;

    switch(grouping){
        case CalendarDateUnit.DAY:
            return secondsToDays(diffInSeconds);

        case CalendarDateUnit.WEEK:
            return secondsToWeeks(diffInSeconds);
        
        case CalendarDateUnit.MONTH:
            return secondsToMonths(diffInSeconds);
    
    }
};

export const addDateUnitToDate = (date: Date, dateUnit: CalendarDateUnit, count: number) => {  
    let secondsToAdd = 0;

    switch(dateUnit){
        case CalendarDateUnit.DAY:
            secondsToAdd = daysToSeconds(count);
            break;

        case CalendarDateUnit.WEEK:
            secondsToAdd = daysToSeconds(count * 7);
            break;
        
        case CalendarDateUnit.MONTH:
            secondsToAdd = daysToSeconds(count * 7 * 4);
            break;
    };

    return new Date(date.getTime() + secondsToAdd);
};

export const getDisplayDateFromGrouping = (date: string, grouping: CalendarDateUnit = CalendarDateUnit.WEEK) => {
    const _date = new Date(date);

    switch(grouping){
        case CalendarDateUnit.DAY:
            return `${_date.getMonth() + 1}/${_date.getDate()}/${_date.getFullYear()}`;

        case CalendarDateUnit.WEEK:
            return `${_date.getMonth() + 1}/${Math.ceil(_date.getDate()/7)}/${_date.getFullYear()}`;
        
        case CalendarDateUnit.MONTH:
            return `${_date.toLocaleString('default', { month: 'long' })} ${_date.getFullYear()}`;
    
    }
};
