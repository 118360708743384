import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { GlobalStyles } from '@soluto-private/mx-asurion-ui-react';
import { AuthProvider } from 'react-oidc-context';

import { Dashboard, JiraOAuth, Login } from './pages';
import { oidcConfig, withAuth } from './components';
import { ROUTES } from './routes';
import { Suspense } from 'react';

const App = () => (
    <BrowserRouter>
        <GlobalStyles />
        <AuthProvider {...oidcConfig}>
            <Suspense>
                <Routes>
                    <Route path={ROUTES.DASHBOARD} Component={withAuth(Dashboard)} />
                    <Route path={ROUTES.LOGIN} Component={Login} />
                    <Route path={ROUTES.JIRA_OAUTH} Component={JiraOAuth} />
                </Routes>
            </Suspense>
        </AuthProvider>
    </BrowserRouter>
);

export default App;