import { areaChart, AreaChartParams, lineChart, LineChartParams, radarChart, RadarChartParams } from "./graphs";

export enum GraphType {
    AREA = 'areaChart',
    LINE = 'lineChart',
    RADAR = 'radarChart'
};

export type GraphDataType = AreaChartParams | LineChartParams | RadarChartParams;

export const getGraph = (graphType: GraphType, graphData: GraphDataType) => {
    switch(graphType){
        case GraphType.LINE:
            return lineChart(graphData as LineChartParams);

        case GraphType.AREA:
            return areaChart(graphData as AreaChartParams);

        case GraphType.RADAR:
            return radarChart(graphData as RadarChartParams);
    }
};
